<template>
  <div class="container-md col-lg-11 col-md-11 review-activity pt-2">
    <div class="d-flex">
      <h4 class="text-primary w-100">
        {{ isPendingActivity ? "Job Approval" : "Job Approval" }}
      </h4>
      <CButton
        class="justify-content-end text-nowrap"
        color="primary"
        shape="pill"
        @click="backToList"
        >Back to list</CButton
      >
    </div>
    <div class="mt-3 mb-3" v-if="isPendingActivity">
      <ReviewActivity
        v-if="isPendingActivity"
        :activityDetails="selectedActivity"
        :comments="getComments"
        @changeActivityStatus="changeActivityStatus"
        @openCandidateInfoModal="openCandidateInfoModal"
        @openJobInfoModal="openJobInfoModal"
      />
      <h4 v-else class="text-muted p-4 text-center">
        No Pending Activity Available for Review
      </h4>
    </div>
    <div class="mt-3 mb-3" v-else>
      <CompletedJobReReview
        v-if="isCompletedActivity"
        :activityDetails="selectedActivity"
        :comments="getComments"
        @changeActivityStatus="changeActivityStatus"
        @openCandidateInfoModal="openCandidateInfoModal"
        @openJobInfoModal="openJobInfoModal"
      />
      <h4 v-else class="text-muted p-4 text-center">
        No completed Activity Available for Review
      </h4>
    </div>
    <CandidateInfoModal
      v-if="candidateInfoModal.isShowPopup"
      :candidate_uid="candidateInfoModal.candidate_uid"
      :isShowModal="candidateInfoModal.isShowPopup"
      :isShowContact="false"
      @modalCallBack="candidateInfoModalCallBack"
    />
    <JobInfoModal
      v-if="jobInfoModal.isShowPopup"
      :job_id="jobInfoModal.job_id"
      :isShowModal="jobInfoModal.isShowPopup"
      @modalCallBack="jobInfoModalCallBack"
    />
  </div>
</template>
<script>
import ReviewActivity from "@/containers/ActivityList/JobAuthoriser/ReviewActivity";
import CompletedJobReReview from "@/containers/ActivityList/JobAuthoriser/CompletedJobReReview";
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper.js";
import CandidateInfoModal from "@/components/reusable/CandidateInfoModal";
import JobInfoModal from "@/components/reusable/JobInfoModal";
export default {
  components: {
    ReviewActivity,
    CompletedJobReReview,
    CandidateInfoModal,
    JobInfoModal,
  },
  data() {
    return {
      candidateInfoModal: {
        isShowPopup: false,
        candidate_uid: null,
      },
      jobInfoModal: {
        isShowPopup: false,
        job_id: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "selectedActivity",
      "getComments",
      "activeTab",
      "getCurrency",
    ]),
    isPendingActivity() {
      return (
        isObject(this.selectedActivity) &&
        !isEmptyObjectCheck(this.selectedActivity) &&
        this.selectedActivity.status_id === 28 &&
        this.selectedActivity.sub_status_id !== 74
      );
    },
    isCompletedActivity() {
      return (
        isObject(this.selectedActivity) &&
        !isEmptyObjectCheck(this.selectedActivity) &&
        [28, 29].includes(this.selectedActivity.status_id) &&
        (this.selectedActivity.status_id === 28
          ? this.selectedActivity.sub_status_id === 74
          : true)
      );
    },
  },
  methods: {
    ...mapActions([
      "fetchSelectedJobActivity",
      "approveAuthoriser",
      "fetchSelectedActivityComments",
      "fetchCurrency",
    ]),
    changeActivityStatus(payload) {
      this.approveAuthoriser(payload);
    },
    backToList() {
      // this.isPendingActivity
      //   ? this.$store.commit("SET_ACTIVE_TAB", 0)
      //   : this.$store.commit("SET_ACTIVE_TAB", 1);
      this.$store.commit("SET_ACTIVE_TAB", this.activeTab);
      this.$router.push({ path: `/list-activity` });
    },
    openCandidateInfoModal(candidate_uid) {
      if (candidate_uid) {
        this.candidateInfoModal.candidate_uid = candidate_uid;
        this.candidateInfoModal.isShowPopup = true;
      }
    },
    openJobInfoModal(job_id) {
      if (job_id) {
        this.jobInfoModal.job_id = job_id;
        this.jobInfoModal.isShowPopup = true;
      }
    },
    candidateInfoModalCallBack(action) {
      this.candidateInfoModal.isShowPopup = false;
    },
    jobInfoModalCallBack(action) {
      this.jobInfoModal.isShowPopup = false;
    },
  },
  mounted() {
    const {
      params: { id },
    } = this.$route;
    let job_id = id ? parseInt(id) : null;
    let appendAction = [];
    appendAction = [
      ...appendAction,
      this.fetchSelectedJobActivity({ job_id, action_id: 19 }),
    ];
    appendAction = [
      ...appendAction,
      this.fetchSelectedActivityComments({ job_id, action_id: 19 }),
    ];

    if (!this.getCurrency.length) {
      appendAction = [...appendAction, this.fetchCurrency()];
    }
    Promise.all(appendAction).then((res) => {});
  },
};
</script>